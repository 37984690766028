import _ from 'lodash';
import React from 'react';

import classNames from 'classnames';

// TODO: Should we add position-static when there is no label?

const Checkbox: React.FC<{
  checked: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ checked, className, disabled, label, name, onChange }) => {
  const id = _.uniqueId('checkbox-');

  return (
    <div className={classNames('form-check', className)}>
      <input
        id={id}
        checked={checked}
        className="form-check-input"
        disabled={disabled}
        name={name}
        type="checkbox"
        onChange={onChange}
      />
      {label && (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
