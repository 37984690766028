import classNames from 'classnames';
import React from 'react';

const Column: React.FC<{
  width?: number;
  offset?: number;
  className?: string;
  style?: React.CSSProperties;
}> = ({ width, offset, className, style, children }) => {
  const columnClass = width ? `col-${width}` : 'col';
  const offsetClass = offset ? `offset-${offset}` : '';
  const classes = classNames(columnClass, offsetClass, className);

  return (
    <div style={style} className={classes}>
      {children}
    </div>
  );
};

export default Column;
