import { DateTime } from 'luxon';

// Transformation

const addDays = (days: number, date: Date): Date =>
  toDateTime(date)
    .plus({ days })
    .toJSDate();

const addYears = (years: number, date: Date): Date =>
  toDateTime(date)
    .plus({ years })
    .toJSDate();

export type DateDiff = {
  diff: number;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
};

const dateDiff = (endDate: Date, now: Date): DateDiff => {
  var diff = endDate.getTime() - now.getTime();
  return diff
    ? {
        diff: diff,
        seconds: Math.floor((diff / 1000) % 60),
        minutes: Math.floor((diff / 60000) % 60),
        hours: Math.floor((diff / 3600000) % 24),
        days: Math.floor(diff / 86400000)
      }
    : {
        diff: 0,
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0
      };
};

// Aggregation

const minDate = (date1: Date, date2: Date): Date => aggregateBy(date1, date2, DateTime.min);

const maxDate = (date: Date, date2: Date): Date => aggregateBy(date, date2, DateTime.max);

// Parsing

const parseDate = (dateString: string, defaultDate: Date): Date => internalParseDate(dateString, defaultDate);

const parseDateOrNull = (dateString: string): Date | null => internalParseDate(dateString);

const unsafeParseDate = (value: string): Date => {
  const dateTime = DateTime.fromISO(value);

  if (!dateTime.isValid) {
    throw new Error(`Unable to parse date from string: "${value}"`);
  }

  return dateTime.toJSDate();
};

// Conversions

const toIsoDateString = (date: Date): string => toDateTime(date).toISODate() || '';

const toLocaleDateString = (date: Date): string => toDateTime(date).toLocaleString() || '';

// Helpers

const aggregateBy = (date1: Date, date2: Date, aggregate: (...dateTimes: DateTime[]) => DateTime): Date =>
  aggregate(toDateTime(date1), toDateTime(date2)).toJSDate();

function internalParseDate(dateString: string): Date | null;
function internalParseDate(dateString: string, defaultDate: Date): Date;
function internalParseDate(dateString: string, defaultValue: Date | null = null) {
  const dateTime = DateTime.fromISO(dateString);
  return dateTime.isValid ? dateTime.toJSDate() : defaultValue;
}

const toDateTime = DateTime.fromJSDate;

export {
  addDays,
  addYears,
  dateDiff,
  maxDate,
  minDate,
  parseDate,
  parseDateOrNull,
  toIsoDateString,
  toLocaleDateString,
  unsafeParseDate
};
