import { uniqueId } from 'lodash';
import React, { useState } from 'react';
import './Page.css';

type Props = { content: React.ReactNode }

export default function Page({ content }: Props) {
  return (
    <>
      <a href="#page-content" tabIndex={-1} className="sr-only">Skip to main content</a>

      <div id="app" className="app homepage">
        <div className="homepage-layout homepage-layout-3">
          <header id="page-header">
            <div className="container">
              <a href="https://www.sdrma.org/" title="Go to the homepage">
                <div className="header-logo">
                  <div className="inner">
                    <h1><img src='/static/media/sdrma-logo.png' alt="Special District Risk Management Authority" /></h1>
                  </div>
                </div>
              </a>
              <p className="social-header-streamline"></p>
              <nav className="header-links" aria-label="header">
                <div className="container">
                  <ul className="nav secondary-navigation">
                    <li><a href="https://www.sdrma.org/contact-us">Contact Us</a></li>
                  </ul>
                </div>
              </nav>
              <div role="search">
                <form className="search-box ng-pristine ng-valid">
                  <div className="input-group">
                    <label htmlFor="site-search" className="sr-only">Search:</label>
                    <input type="text" id="site-search" className="form-control" placeholder="Search..." />
                    <span className="input-group-btn">
                      <button className="btn btn-default" type="submit">Go!</button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </header>
          <nav id="page-navigation" className="navbar" aria-label="primary">
            <div className="container">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#page-navigation-navbar" aria-expanded="false" aria-controls="page-navigation-navbar">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span><span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div id="page-navigation-navbar" className="collapse navbar-collapse">
                <ul className="nav navbar-nav primary-navigation sl-menu" role="menubar" aria-orientation="horizontal">
                  <MenuItem href="https://www.sdrma.org/" title="Home" />
                  <MenuItem href="https://www.sdrma.org/about-us" title="About SDRMA" subMenuItems={[
                    { href: "https://www.sdrma.org/about-us", title: "About Us Overview" },
                    { href: "https://www.sdrma.org/sdrma-staff", title: "SDRMA Staff" },
                    { href: "https://www.sdrma.org/career-center", title: "Career Center" },
                    { href: "https://www.sdrma.org/employment-application", title: "Employment Application" },
                    { href: "https://www.sdrma.org/news", title: "News" }
                  ]} />
                  <MenuItem href="https://www.sdrma.org/governance" title="Governance" subMenuItems={[
                    { href: "https://www.sdrma.org/board-of-directors", title: "Board of Directors" },
                    { href: "https://www.sdrma.org/board-meetings", title: "Board Meetings" },
                    { href: "https://www.sdrma.org/submit-a-public-records-request", title: "Public Records Request" }
                  ]} />
                  <MenuItem href="https://www.sdrma.org/resources" title="Resources" subMenuItems={[
                    { href: "https://www.sdrma.org/srdma-documents", title: "SDRMA Documents" },
                    { href: "https://www.sdrma.org/memberplus-services", title: "MemberPlus Services" },
                    { href: "https://www.sdrma.org/risk-control", title: "Risk Control" },
                    { href: "https://www.sdrma.org/spring-education-day", title: "Spring Education Day 2024" },
                    { href: "https://www.sdrma.org/vendor-partners", title: "Vendor Partners" }
                  ]} />
                  {/*
                      { href: "https://www.sdrma.org/vendor-partners", title: "Vendor Partners" }
                        <ul className="dropdown-menu sl-menu__submenu" id="sl-menu__submenu_5" role="menu"
                          aria-orientation="vertical" aria-label="Vendor Partners">
                          <li role="none"><a href="https://www.sdrma.org/vector-solutions" role="menuitem" tabIndex={-1}>Vector Solutions</a>
                          </li>
                          <li role="none"><a href="https://www.sdrma.org/company-nurse" role="menuitem" tabIndex={-1}>Company Nurse</a></li>
                          <li role="none"><a href="https://www.sdrma.org/california-special-districts-alliance" role="menuitem"
                            tabIndex={-1}>California Special Districts Alliance</a></li>
                        </ul>
                  */}

                  <MenuItem href="https://www.sdrma.org/program-coverages" title="Program Coverages" subMenuItems={[
                      { href: "https://www.sdrma.org/program-coverages", title: "Program Coverages Overview" },
                      { href: "https://www.sdrma.org/property-liability-program", title: "Property/Liability Program" },
                      { href: "https://www.sdrma.org/workers-compensation-program", title: "Workers' Compensation Program" },
                      { href: "https://www.sdrma.org/health-benefits-program", title: "Health Benefits Program" }
                    ]} />

                  <MenuItem href="https://www.sdrma.org/calendar" title="Calendar" />
                  <MenuItem href="https://www.sdrma.org/contact-us" title="Contact" />
                </ul>
              </div>
            </div>
          </nav>
          <main id="page-content">
            <div className="container">
              <div id="content" className="site-content">
                <div id="primary" className="content-area">
                  <main id="main" className="site-main" role="main">
                    <div className="container">
                      <div className="entry-content" style={{ margin: '4em 0' }}>
                        <div className="form-container">
                          <div id="wrapper">
                            <div className="container-fluid">
                              <div id="main">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    <div id="wideCol">{content}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <footer className="entry-footer"></footer>
                  </main>
                </div>
              </div>
            </div>
          </main>
          <footer id="page-footer" className="clearfix">
            <div className="container">
              <div className="columns">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="footer-copyright">Copyright © 2024 Special District Risk Management Authority</p>
                      <p className="footer-address">1112 I Street, Suite 300., Sacramento CA 95814</p>
                      <p className="footer-phone"><span className="phone-label">Telephone</span>&nbsp;<span
                        className="phone-number">(800) 537-7790</span></p>
                      <p className="social-footer-streamline"></p>
                    </div>
                    <div className="col-md-6">
                      <nav className="footer-links" aria-label="footer">
                        <div className="container">
                          <ul className="footer-navigation">
                            <li><a href="https://www.sdrma.org/privacy-policy">Privacy Policy</a></li>
                          </ul>
                        </div>
                      </nav>
                      <p className="footer-streamline footer-static-transparency"><a className="streamline" href="transparency.html"
                        title="Special District Transparency Report">District Transparency</a></p>
                      <p className="footer-streamline">Powered by&nbsp;<a className="streamline external"
                        href="http://www.getstreamline.com/"
                        title="Streamline: Technology for Special Districts">Streamline</a>&nbsp;|&nbsp;<a
                          href="https://sdrma.specialdistrict.org/users/sign_in?destination=%2F">Sign in</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

type MenuItemProps = {
  href?: string;
  title: string;
  subMenuItems?: MenuItemProps[];
}

function MenuItem(props: MenuItemProps) {
  const [menuHover, setMenuHover] = useState(false);
  const [idValue] = useState(uniqueId('sl-menu__submenu_'));

  const beginListHover = () => {
    setMenuHover(true);
  }

  const endListHover = () => {
    setMenuHover(false);
  }

  return !!props.subMenuItems ?
    <li role="none" className={`sl-menu--has-submenu ${menuHover ? "sl-menu--open" : "sl-menu--closed"}`} onMouseEnter={beginListHover} onMouseLeave={endListHover} ><a href={props.href} role="menuitem" aria-haspopup="menu" aria-controls={idValue} aria-expanded={menuHover} tabIndex={-1}>{props.title}</a>
      <button type="button" className="sl-menu__toggle" aria-controls={idValue} tabIndex={-1} aria-expanded={menuHover}>Toggle menu</button>
      <ul className="dropdown-menu sl-menu__submenu" id={idValue} role="menu" aria-orientation="vertical" aria-label="About SDRMA">
        {props.subMenuItems.map((s, i) => <li key={i} role="none"><a href={s.href} role="menuitem" tabIndex={-1}>{s.title}</a></li>)}
      </ul>
    </li> :
    <li role="none"><a href={props.href} role="menuitem" tabIndex={0}>{props.title}</a></li>

}