import _ from 'lodash';

const jsonDateStringRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
const jsonDateTimeStringRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{1,3})?$/;

const convertJsonDateStringsToDates = (json: object[] | object): any => {
  if (json instanceof Array) {
    return json.map(convertJsonDateStringsToDates);
  }

  const object = _.clone(json);

  Object.keys(object).forEach(key => {
    // @ts-ignore
    const value = object[key];

    if (value instanceof Object) {
      // @ts-ignore
      object[key] = convertJsonDateStringsToDates(value);
    } else if (jsonDateTimeStringRegex.test(value)) {
      // @ts-ignore
      object[key] = new Date(value);
    } else if (jsonDateStringRegex.test(value)) {
      // @ts-ignore
      object[key] = new Date(`${value}T00:00:00`);
    }
  });

  return object;
};

export default convertJsonDateStringsToDates;
