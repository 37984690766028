import { Field, FieldProps, getIn } from 'formik';
import React from 'react';

const FieldErrors: React.FC<{ name: string }> = ({ name }) => (
  <Field name={name}>
    {({ form }: FieldProps) => (
      <div className="invalid-feedback">{getIn(form.errors, name)}</div>
    )}
  </Field>
);

export default FieldErrors;
