// A *temporary* dumping ground for utility functions until they find a better home

export const getPayrollTotal = (
  auditComplete: boolean | null,
  payrollEntries: Array<{
    actualEmployeePayroll: number | null;
    estimatedEmployeePayroll: number | null;
  }>
): number => {
  if (auditComplete) {
    return payrollEntries
      ? payrollEntries.reduce(
          (sum: number, value) => sum + (value.actualEmployeePayroll || 0),
          0
        )
      : 0;
  } else {
    return payrollEntries
      ? payrollEntries.reduce(
          (sum: number, value) => sum + (value.estimatedEmployeePayroll || 0),
          0
        )
      : 0;
  }
};

export const renderYesNo = (value: string | null | undefined) => {
  if (value !== null && value !== undefined) {
    return value.toString() === 'true'
      ? (value = 'Yes')
      : value.toString() === 'false'
      ? (value = 'No')
      : value;
  } else {
    return value;
  }
};
