import * as Yup from 'yup';
import { setLocale } from 'yup';
import { toLocaleDateString } from '../util/date';

// TODO: Can we type `this` properly (i.e. not `any`) in these `test` functions?
// `this` should be Yup.TestContext, but `resolve` is not defined in the typings

function minRef(this: Yup.DateSchema, ref: Yup.Ref, refLabel: string) {
  return this.test({
    name: 'after',
    // eslint-disable-next-line no-template-curly-in-string
    message: '${label} must be on or after ${refLabel}',
    params: { refLabel },
    test(this: any, value: Date | undefined) {
      const limit: Date | undefined = this.resolve(ref);

      if (value === undefined || limit === undefined) {
        return true;
      }

      return value >= limit;
    }
  });
}

function maxRef(this: Yup.DateSchema, ref: Yup.Ref, refLabel: string) {
  return this.test({
    name: 'before',
    // eslint-disable-next-line no-template-curly-in-string
    message: '${label} must be on or before ${refLabel}',
    params: { refLabel },
    test(this: any, value: Date | undefined) {
      const limit: Date | undefined = this.resolve(ref);

      if (value === undefined || limit === undefined) {
        return true;
      }

      return value <= limit;
    }
  });
}

// This seems to do the trick, but see
// https://github.com/jquense/yup/issues/312#issuecomment-442854307
// for a heavier-weight solution if necessary
declare module 'yup' {
  interface DateSchema {
    maxRef: typeof maxRef;
    minRef: typeof minRef;
  }

  interface NumberSchema {
    allowFormatting: typeof allowFormatting;
  }
}

export function configureValidation() {
  setLocale({
    // eslint-disable-next-line no-template-curly-in-string
    mixed: { required: '${label} is required' },
    // Typings don't support min/max as functions
    date: {
      max: (options: { label: string; max: Date }): string =>
        `${options.label} must be on or before ${toLocaleDateString(
          options.max
        )}`,
      min: (options: { label: string; min: Date }): string =>
        `${options.label} must be on or after ${toLocaleDateString(
          options.min
        )}`
    } as any // Typings don't support min/max as functions
  });

  Yup.addMethod(Yup.date, 'minRef', minRef);
  Yup.addMethod(Yup.date, 'maxRef', maxRef);

  Yup.addMethod(Yup.number, 'allowFormatting', allowFormatting);
}

function allowFormatting(this: Yup.NumberSchema) {
  return this.transform((value, originalValue) => {
    if (this.isType(value)) {
      return value;
    }

    const numericString = originalValue.replace(/[^0-9\-.]/g, '');
    return this.cast(numericString === '-' ? '0' : numericString);
  });
}

export const formattedNumber = () => Yup.number().allowFormatting();
