import * as Yup from 'yup';

export const urlSchema = Yup.string().test({
  name: 'validUrl',
  exclusive: true,
  message: 'Invalid Web URL format.',
  test: url =>
    Yup.string()
      .url()
      .isValidSync(url) ||
    Yup.string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(www\.)?[a-z0-9A]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:([0-9]){1,4})?(\/.*)?$/
      )
      .isValidSync(url)
});
