import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { LoadingMask } from '../../shared/components';

type Size = 'small' | 'medium' | 'large' | 'extra-large';

const maxHeightStyle = css`
  max-height: 600px;
`;

const ModalBody = styled.div.attrs({ className: 'modal-body' })`
  /* TODO: Should max height always apply or should it be opt-in? */
  ${(props: { useMaxHeight: boolean }) => (props.useMaxHeight ? maxHeightStyle : '')}
  overflow-y: auto;
`;

const Modal: React.FC<{
  body?: ReactNode;
  footer?: ReactNode;
  loadingMessage?: string | false;
  size?: Size;
  title: string;
  onClose?: () => void;
  useMaxHeight?: boolean;
}> = ({ body, footer, loadingMessage = false, size = 'medium', title, children, onClose, useMaxHeight = true }) => (
  <>
    <div className="modal show" style={{ display: 'block' }}>
      <div
        className={classNames('modal-dialog modal-dialog-centered', {
          'modal-lg': size === 'large',
          'modal-sm': size === 'small'
        })}
        style={customStyle(size)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">{title}</h6>
            {onClose && (
              <button type="button" className="close" onClick={onClose}>
                ×
              </button>
            )}
          </div>
          <ModalBody useMaxHeight={useMaxHeight}>{body || children}</ModalBody>
          {footer && <div className="modal-footer">{footer}</div>}
          {loadingMessage && <LoadingMask fullScreen={true} message={loadingMessage as string} />}
        </div>
      </div>
    </div>
    <div className="modal-backdrop show" />
  </>
);

const customStyle = (size: Size) => (size === 'extra-large' ? { maxWidth: 1260 } : {});

export default Modal;
