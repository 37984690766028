import { Field, FieldProps } from 'formik';
import React, { RefObject, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { fieldClassNames } from '../class-helpers';
import dirtyStyles from '../dirty-styles';

type Props = {
  disabled?: boolean;
  elementRef?: RefObject<HTMLTextAreaElement>;
  name: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const FormikTextArea: React.FunctionComponent<Props> = ({
  disabled,
  elementRef,
  name,
  ...attributes
}) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <StyledTextArea
        ref={elementRef}
        {...field}
        className={fieldClassNames(form, name, disabled)}
        disabled={disabled}
        {...attributes}
      />
    )}
  </Field>
);

const StyledTextArea = styled.textarea`
  ${dirtyStyles};
`;

export default FormikTextArea;
