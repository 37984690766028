import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { HashRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import graphqlClient from '../graphql-client';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Register from './pages/Register';

export default function App() {
  return (
    <HashRouter>
      <Switch>
        <ApolloProvider client={graphqlClient}>
          <Viewport>
            <Route exact={true} path="/" component={Login} />
            <Route exact={true} path="/login" component={Login} />
            <Route exact={true} path="/register" component={Register} />
            <Route exact={true} path="/forgot-password" component={ForgotPassword} />
          </Viewport>
        </ApolloProvider>
      </Switch>
    </HashRouter>
  );
}

const Viewport = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
