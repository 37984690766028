import { Field, FieldProps, FieldValidator, FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { fieldClassNames } from '../class-helpers';
import dirtyStyles from '../dirty-styles';
import { handleSelectChange } from '../event-helpers';
import FieldErrors from '../FieldErrors';

const FormikSelect: React.FC<{
  disabled?: boolean;
  name: string;
  options?: React.ReactNode;
  multiple?: boolean;
  onChange?: (form: FormikProps<any>, event: React.ChangeEvent<HTMLSelectElement>) => void;
  style?: React.CSSProperties;
  validate?: FieldValidator;
}> = ({ disabled, name, validate, options, multiple, onChange, style, children }) => (
  <Field name={name} validate={validate}>
    {({ field, form }: FieldProps) => (
      <>
        <StyledSelect
          {...field}
          multiple={multiple}
          className={fieldClassNames(form, name, disabled, {
            useEagerValidation: true
          })}
          disabled={disabled}
          onChange={handleSelectChange(form, field, onChange)}
          style={style}
        >
          {options || children}
        </StyledSelect>
        <FieldErrors name={name} />
      </>
    )}
  </Field>
);

export default FormikSelect;

const StyledSelect = styled.select`
  ${dirtyStyles};
`;
