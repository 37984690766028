import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div.attrs({
  className: 'fas fa-spinner fa-spin'
})`
  margin: 2px 10px 2px 0px;
  box-shadow: none !important;
`;

const ButtonSpinner: React.FunctionComponent<{ message?: string }> = ({
  message = 'Loading...'
}) => (
  <div className="text-center">
    <Spinner />
    {message}
  </div>
);

export default ButtonSpinner;
