/* eslint-disable */
/*global google*/
/// <reference types="@types/google.maps" />

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

type Address = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
} | null;

type Props = { addresses: Address[]; defaultZoom: number };
type Coordinates = { lat: number; lng: number };

// California
const defaultCenter = { lat: 37.1839989, lng: -123.8056437 };

const PlacesMap: React.FC<Props> = ({ defaultZoom, ...props }) => {
  const ref = useRef<any>();
  const [map, setMap] = useState<google.maps.Map>();
  const [coordinates, setCoordinates] = useState<Coordinates>();

  const handleResults = useCallback(
    (results: Array<google.maps.GeocoderResult | google.maps.places.PlaceResult>) => {
      const location = results[0].geometry!.location;
      if (map && location) {
        const newCoordinates = { lat: location.lat(), lng: location.lng() };
        setCoordinates(newCoordinates);
        (map as any).panTo(newCoordinates);
      }
    },
    [map, setCoordinates]
  );

  const geocode = useCallback(
    (addresses: string[]) => {
      if (addresses.length === 0) {
        return;
      }

      const geocoder = new google.maps.Geocoder();

      if (addresses[0]) {
        geocoder.geocode({ address: addresses[0] }, (results, status) =>
          status === 'OK' && results ? handleResults(results) : geocode(addresses.slice(1))
        );
      } else {
        geocode(addresses.slice(1));
      }
    },
    [handleResults]
  );

  const search = useCallback(
    (addresses: string[]) => {
      if (addresses.length === 0) {
        return;
      }

      const service = new google.maps.places.PlacesService(
        (map as any).context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
      );

      if (addresses[0]) {
        service.textSearch({ query: addresses[0] }, (results, status) =>
          status === 'OK' && results ? handleResults(results) : search(addresses.slice(1))
        );
      } else {
        search(addresses.slice(1));
      }

      if (!coordinates) {
        geocode(addresses);
      }
    },
    [map, handleResults, coordinates, geocode]
  );

  useEffect(() => {
    if (ref != null && !map) {
      setMap(ref.current);
    }

    if (map && !coordinates) {
      const addresses = props.addresses.map(address => addressToString(address));
      search(addresses);
    }
  }, [ref, map, setMap, coordinates, search, props.addresses]);

  return (
    <GoogleMap
      ref={ref}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      options={{
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: true,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        panControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        }
      }}
    >
      {coordinates && <Marker position={coordinates} clickable={false} />}
    </GoogleMap>
  );
};

const EnhancedMap = withScriptjs(withGoogleMap(PlacesMap));

export default (props: Props) => (
  <EnhancedMap
    googleMapURL={googleMapUrl}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `100%` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    {...props}
  />
);

const addressToString = (address: Address | null) =>
  address
    ? `${address.line1} ${address.line2}, ${address.city}, ${address.state} ${address.postalCode.slice(0, 5)}`
    : '';

const googleApiKey = 'AIzaSyC1-8XVepx3yoZYrYo-b1eUOm7Ritz0dsI';
const googleMapUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleApiKey}&libraries=geometry,drawing,places`;
