import { Just, Maybe, Nothing } from '../../shared/maybe';

// Currency

const formatCurrency = (value: number, decimalPlaces: number = 2): string =>
  '$' +
  value.toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });

// Decimals

const formatDecimal = (value: number, maximumFractionDigits?: number): string =>
  value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits || 2
  });

const parseDecimal = (value: string): Maybe<number> => {
  const numericString = value.replace(/[^0-9-.]/g, '');
  const decimal = parseFloat(numericString);
  return isNaN(decimal) ? Nothing : Just(decimal);
};

const parseDecimalOrNull = (value: string): number | null => {
  const maybe = parseDecimal(value);
  return Maybe.valueOrNull(maybe);
};

const unsafeParseDecimal = (value: string): number => {
  const maybe = parseDecimal(value);

  if (Maybe.isNothing(maybe)) {
    throw new Error(`Unable to parse decimal from string: "${value}"`);
  }

  return maybe.value;
};

// Integers

const padZero = (num: number): string => (num || num === 0 ? (num < 10 ? '0' + num : `${num}`) : '');

const formatInteger = (value: number): string =>
  value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

const parseInteger = (value: string): Maybe<number> => Maybe.map(Math.floor, parseDecimal(value));

const parseIntegerOrNull = (value: string): number | null => {
  const maybe = parseInteger(value);
  return Maybe.valueOrNull(maybe);
};

const unsafeParseInteger = (value: string): number => {
  const maybe = parseInteger(value);

  if (Maybe.isNothing(maybe)) {
    throw new Error(`Unable to parse integer from string: "${value}"`);
  }

  return maybe.value;
};

const roundUp = (value: number) => {
  const rounded = Math.round(value);

  return value ? (value >= rounded ? rounded + 1 : rounded) : value;
};

// Percentages

const formatPercent = (value: number): string => value.toLocaleString('en-US', { style: 'percent' });

const parsePercentOrNull = (value: string): number | null => {
  const maybe = parseInteger(value);

  const percent = Maybe.valueOrNull(maybe);

  return percent ? percent / 100 : percent === 0 ? 0 : null;
};

// Risk Factors

const formatRiskFactor = (riskFactor: number): string => {
  if (riskFactor === 1) {
    return '1.0';
  }

  return formatDecimal(riskFactor).replace(/^0/, '');
};

export {
  formatCurrency,
  formatDecimal,
  parseDecimal,
  parseDecimalOrNull,
  formatInteger,
  padZero,
  parseInteger,
  parseIntegerOrNull,
  unsafeParseDecimal,
  unsafeParseInteger,
  formatPercent,
  parsePercentOrNull,
  formatRiskFactor,
  roundUp
};
