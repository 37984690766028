import { Form, Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button } from '../../../shared/components';
import { TextField } from '../../../shared/forms';
import { LoginMutation, LoginMutationVariables } from '../../graphql-types';
import ButtonSpinner from '../../shared/components/ButtonSpinner';
import Page from '../components/Page';

type Values = {
  email: string;
  password: string;
};

export default function Login() {
  const [mutate] = useMutation<LoginMutation, LoginMutationVariables>(mutation);
  const [error, setError] = useState('');

  const handleSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
    if (error) {
      setError('');
    }

    mutate({ variables: { input: { ...values } } }).then(result => {
      if (result.data && result.data.login) {
        if (result.data.login.successful) {
          window.location.assign('/');
        } else {
          setError(result.data.login.message);
          resetForm({ values: { email: values.email, password: '' } });
          setSubmitting(false);
        }
      }
    });
  };

  return (
    <Page
      content={
        <Formik<Values>
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty, isValid, submitForm, isSubmitting }) => (
            <Form translate="yes" onKeyPress={dirty && isValid ? handleKeyPress(submitForm) : undefined}>
              <FormContainer>
                <h1>MemberPlus Online Login</h1>
                <p>Please log in with your SDRMA registered email address and password.</p>
                {error && <Error>{error}</Error>}
                <div id="fields" style={{ maxWidth: '35%' }} className="d-flex flex-column">
                  <TextField type="email" name="email" labelText="Email" />
                  <TextField type="password" name="password" labelText="Password" />
                  <p />
                  <Button className="btn btn-default" buttonStyle="dark" disabled={!dirty || !isValid} onClick={submitForm}>
                    {isSubmitting ? <ButtonSpinner message="Logging in..." /> : 'LOG IN'}
                  </Button>
                  <p />
                  <Link to="/register" style={{ color: '#b28705' }}>
                    Register as a new user
                  </Link>
                  <br />
                  <Link to="/forgot-password" style={{ color: '#b28705' }}>
                    Forgot password?
                  </Link>
                </div>
              </FormContainer>
            </Form>
          )}
        </Formik>
      }
    />
  );
}

const Error = styled.div`
  display: block;
  margin: 0.75rem 0;
  color: #dc3545;
`;

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid Email Address format').label('Email').required(),
  password: Yup.string().label('Password').required()
});

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  > :not(#fields) {
    padding-left: 15px;
  }
`;

const handleKeyPress = (submitForm: () => void) => (event: React.KeyboardEvent) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
    submitForm();
  }
};

const mutation = gql`
  mutation LoginMutation($input: LoginUserAccountInput!) {
    login(input: $input) {
      successful
      message
    }
  }
`;
