import { Field, FieldProps } from 'formik';
import React from 'react';
import Checkbox from '../basic/Checkbox';

const FormikCheckbox: React.FC<{
  className?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ className, disabled, label, name, onChange }) => (
  <Field name={name}>
    {({ field }: FieldProps) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);

        if (onChange) {
          onChange(event);
        }
      };

      return (
        <Checkbox
          checked={field.value === true}
          className={className}
          disabled={disabled}
          label={label}
          name={name}
          onChange={handleChange}
        />
      );
    }}
  </Field>
);

export default FormikCheckbox;
