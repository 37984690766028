import React from 'react';

type Props = {
  values: unknown;
  handler: (() => void) | undefined;
};

// Could probably be more generic; this works for today's use cases.
class FormikOnChange extends React.Component<Props> {
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.handler && this.props.values !== prevProps.values) {
      this.props.handler();
    }
  }

  render() {
    return null;
  }
}

export default FormikOnChange;
