/* eslint-disable */
// Definition
type Just<T> = Opaque<'Just', { value: T }>;
type Nothing = Opaque<'Nothing'>;
export type Maybe<T> = Just<T> | Nothing;

// Constructor functions

export const Just = <T>(value: T): Just<T> => ({ value } as Just<T>);
export const Nothing: Nothing = { kind: 'Nothing' };

// Helpers

export const isNothing = <T>(maybe: Maybe<T>): maybe is Nothing =>
  maybe.kind === 'Nothing';

export const map = <A, B>(f: (value: A) => B, maybe: Maybe<A>): Maybe<B> =>
  isNothing(maybe) ? Nothing : Just(f(maybe.value));

export const valueOrNull = <T>(maybe: Maybe<T>): T | null =>
  isNothing(maybe) ? null : maybe.value;

export const withDefault = <T>(defaultValue: T, maybe: Maybe<T>): T =>
  isNothing(maybe) ? defaultValue : maybe.value;

// Supports qualified usages, e.g.: Maybe.map(...)
export const Maybe = {
  isNothing,
  map,
  valueOrNull,
  withDefault
};

// Opaque type helper; may be extracted if/when mature/useful
type Opaque<K, T = {}> = T & { kind: K };
