import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import useDisabledReasons, { DisabledReasons } from '../../../policy/hooks/use-disabled-reasons';

export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link';

const defaultButtonStyle: ButtonStyle = 'secondary';

export type Size = 'small' | 'large';

export type ButtonType = 'button' | 'submit';

type CommonProps = {
  buttonStyle?: ButtonStyle;
  size?: Size;
};

type ButtonProps = CommonProps & {
  type?: ButtonType;
  href?: string;
  target?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
  href,
  target,
  buttonStyle = defaultButtonStyle,
  style,
  className,
  size,
  disabled,
  type = 'button',
  children,
  ...attributes
}) =>
  href ? (
    <a
      className={`${classes(buttonStyle, size, className)}${disabled ? ' disabled' : ''}`}
      href={href}
      target={target}
      style={{ pointerEvents: 'auto', ...style }}
      title={attributes.title}
    >
      {children}
    </a>
  ) : (
    <button
      className={classes(buttonStyle, size, className)}
      type={type}
      disabled={disabled}
      style={style}
      {...attributes}
    >
      {children}
    </button>
  );

type LinkButtonProps = CommonProps & {
  disabled?: boolean;
  to: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
  buttonStyle = defaultButtonStyle,
  className,
  disabled,
  size,
  ...attributes
}) =>
  disabled ? (
    <button
      className={classes(buttonStyle, size, className)}
      type="button"
      disabled={disabled}
      style={attributes.style}
      title={attributes.title}
    >
      {attributes.children}
    </button>
  ) : (
    <Link className={classes(buttonStyle, size, classNames(className, { disabled }))} {...attributes} />
  );

export const ReasonsButton: React.FC<{ disabledReasons?: DisabledReasons } & ButtonProps> = ({
  disabledReasons,
  ...props
}) => {
  const { disabled, title } = useDisabledReasons(disabledReasons, { disabled: props.disabled, title: props.title });

  return <Button {...props} disabled={disabled} title={title} />;
};

export const classes = (buttonStyle: ButtonStyle, size: Size | undefined, className: string | undefined) =>
  classNames('btn', `btn-${buttonStyle}`, sizeToClass(size), className);

const sizeToClass = (size: Size | undefined): string => {
  switch (size) {
    case 'large':
      return 'btn-lg';
    case 'small':
      return 'btn-sm';
    default:
      return '';
  }
};
