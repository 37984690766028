import { IDoesFilterPassParams, IFilter, IFilterParams } from 'ag-grid-community';
import { chain, xor } from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { renderYesNo } from '../util';

const Wrapper = styled.div`
  max-height: 40rem;
  min-width: 12rem;
`;

export default forwardRef<IFilter, IFilterParams>((props, ref) => {
  const [selectedValues, setSelectedValues] = useState<string[] | null>(null);

  useImperativeHandle(ref, (): IFilter => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        return !selectedValues || selectedValues.includes(props.valueGetter(params.node));
      },

      isFilterActive() {
        return !!selectedValues && selectedValues.length > 0;
      },

      getModel() {
        return selectedValues;
      },

      setModel(values: string[] | null) {
        setSelectedValues(values);
      }
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [selectedValues, props]);

  const handleChange = (value: string) => () => {
    setSelectedValues(xor(selectedValues, [value]));
  };

  const optionValues = getOptionValues(props);

  return (
    <Wrapper>
      <div className="form-group">
        {optionValues.map(value => (
          <div key={value} className="form-check">
            <label className="form-check-label text-nowrap">
              <input
                type="checkbox"
                className="form-check-input"
                checked={!!selectedValues && selectedValues.includes(value)}
                onChange={handleChange(value)}
              />
              {renderYesNo(value)}
            </label>
          </div>
        ))}
      </div>
    </Wrapper>
  );
});

const getOptionValues = (params: IFilterParams) => {
  const values: string[] = [];

  params.rowModel.forEachNode(node => {
    const value = params.valueGetter(node);

    if (!values.includes(value)) {
      values.push(value);
    }
  });

  return chain(values).uniq().sort().value();
};
