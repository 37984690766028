import classNames from 'classnames';
import React from 'react';

export type Size = 'small' | 'large';

type Props = {
  col?: number;
  size?: Size;
};

const Label: React.FC<Props & React.LabelHTMLAttributes<HTMLLabelElement>> = ({
  className,
  col,
  size,
  ...attributes
}) => {
  const classes = classNames(
    col ? `col-${col} col-form-label` : '',
    sizeToClass(size),
    className
  );

  return <label className={classes} {...attributes} />;
};

const sizeToClass = (size: Size | undefined): string => {
  switch (size) {
    case 'large':
      return 'col-form-label-lg';
    case 'small':
      return 'col-form-label-sm';
    default:
      return '';
  }
};

export default Label;
