import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';

type Options = {
  useEagerValidation?: boolean;
};

export const fieldClassNames = (
  form: FormikProps<any>,
  name: string,
  disabled?: boolean,
  options: Options = { useEagerValidation: false }
) =>
  inputClassNames({
    dirty:
      !disabled && getIn(form.values, name) !== getIn(form.initialValues, name),
    invalid:
      !!getIn(form.errors, name) &&
      (options.useEagerValidation || !!getIn(form.touched, name))
  });

export const inputClassNames = ({
  dirty = false,
  plainText = false,
  invalid = false
} = {}): string =>
  classNames({
    dirty,
    'is-invalid': invalid,
    'form-control': !plainText,
    'form-control-sm': true,
    'form-control-plaintext': plainText
  });
