import { ValueFormatterParams, ValueParserParams } from 'ag-grid-community';
import ListFilter from '../../../policy/components/ListFilter';
import {
  formatDecimal,
  formatInteger,
  formatCurrency,
  formatPercent,
  formatRiskFactor,
  parseIntegerOrNull
} from '../../util/number';
import { ColumnDefinition } from './column-definition';
import { formatPhoneNumber, formatZipCode } from './util';

const numericColumn = {
  cellClass: 'ag-numeric-cell',
  headerClass: 'ag-numeric-header'
};

const columnTypes: { [key: string]: ColumnDefinition } = {
  currencyColumn: {
    ...numericColumn,
    valueFormatter: (params: ValueFormatterParams) =>
      params.value === null || params.value === undefined
        ? ''
        : formatCurrency(params.value, 0),
    valueParser: (params: ValueParserParams) =>
      parseIntegerOrNull(params.newValue)
  },
  dateColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      browserDatePicker: true,
      suppressAndOrCondition: true
    },
    valueFormatter: params =>
      params.value === null
        ? ''
        : new Date(params.value).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          })
  },
  dateTimeColumn: {
    filter: 'agDateColumnFilter',
    filterParams: {
      browserDatePicker: true,
      suppressAndOrCondition: true
    },
    valueFormatter: params =>
      params.value === null
        ? ''
        : new Date(params.value).toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
            hour: 'numeric',
            minute: '2-digit'
          })
  },
  decimalColumn: {
    ...numericColumn,
    cellRenderer: (params: { value: number | null | undefined }) =>
      params.value === null || params.value === undefined
        ? ''
        : formatDecimal(params.value)
  },
  integerColumn: {
    ...numericColumn,
    valueFormatter: (params: ValueFormatterParams) =>
      params.value === null || params.value === undefined
        ? ''
        : formatInteger(params.value),
    valueParser: (params: ValueParserParams) =>
      parseIntegerOrNull(params.newValue)
  },
  plainNumberColumn: { ...numericColumn },
  percentColumn: {
    ...numericColumn,
    cellRenderer: params => formatPercent(params.value || 0)
  },
  phoneNumberColumn: {
    cellRenderer: params => formatPhoneNumber(params.value)
  },
  riskFactorColumn: {
    ...numericColumn,
    cellRenderer: params => formatRiskFactor(params.value)
  },
  booleanColumn: {
    cellRenderer: params => (params.value ? 'Yes' : 'No'),
    filterFramework: ListFilter
  },
  zipCodeColumn: {
    valueFormatter: params => formatZipCode(params.value)
  }
};

export default columnTypes;
