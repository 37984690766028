import {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  FilterChangedEvent,
  GetNodeChildDetails,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  RowDataChangedEvent,
  RowDragEvent,
  RowGroupOpenedEvent,
  RowSelectedEvent,
  SelectionChangedEvent,
  ValueGetterParams
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import RouteLink from '../../../policy/components/RouteLink';
import Flex from '../Flex';
import { ColumnDefinition } from './column-definition';
import columnTypes from './column-types';
import './Grid.css';
import RecordCount from './RecordCount';

export const Wrapper = styled.div.attrs({
  className: 'ag-theme-balham h-100 w-100'
})`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const Toolbar = styled.div.attrs({
  className: 'pb-1 toolbar'
})`
  display: inline-flex;
  align-items: flex-end;
  padding-left: 6px;

  > {
    margin-bottom: 0.25rem;
  }

  > :not(:last-child) {
    margin-right: 0.25rem;
  }
`;

type Props<T> = {
  autoHeight?: boolean;
  columns: ColumnDefinition[];
  data?: Array<T>;
  defaultFilterState?: { [key: string]: unknown };
  disableSorting?: boolean;
  disableFiltering?: boolean;
  getNodeChildDetails?: GetNodeChildDetails;
  getRowClass?: (params: any) => string;
  selectFirstRowOnReady?: boolean;
  suppressRecordCount?: boolean;
  suppressToolbar?: boolean;
  toolbarWidgets?: React.ReactNode;
  onRowSelected?: (rowData: T) => void;
  onSelectionChanged?: (selectedRows: T[]) => void;
  onCheckRowSelectability?: (row: T) => boolean;
  onRowGroupOpened?: (row: T, rowIndex: number) => void;
  selectedRowCount?: number;
  onRowDragMove?: (event: RowDragEvent) => void;
  onRowDragLeave?: (event: RowDragEvent) => void;
  onRowDragEnd?: (event: RowDragEvent) => void;
  onRowDragEnter?: (event: RowDragEvent) => void;
  recordType?: string;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  getRowNodeId?: (data: T) => string;
};

const Grid = <T extends {}>({
  autoHeight,
  columns,
  data,
  defaultFilterState,
  disableSorting = false,
  disableFiltering = false,
  getNodeChildDetails,
  getRowClass,
  selectFirstRowOnReady = false,
  suppressRecordCount = false,
  suppressToolbar = false,
  toolbarWidgets,
  onRowSelected,
  onSelectionChanged,
  onCheckRowSelectability,
  onRowGroupOpened,
  selectedRowCount,
  onRowDragMove,
  onRowDragLeave,
  onRowDragEnd,
  onRowDragEnter,
  recordType,
  onCellValueChanged,
  getRowNodeId
}: Props<T>) => {
  const [displayedRecordCount, setDisplayedRecordCount] = useState<number | null>(null);
  const [gridApi, setGridApi] = useState<GridApi>();

  const handleFilterChangedEvent = (_event: FilterChangedEvent) => {
    if (gridApi) {
      setDisplayedRecordCount(gridApi.getDisplayedRowCount());
      handleSummaryFields(columns, gridApi);
    }
  };

  const onClearFilters = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  };

  const handleGridReady = (event: GridReadyEvent) => {
    if (event.api) {
      setGridApi(event.api);
      event.api.sizeColumnsToFit();

      if (selectFirstRowOnReady) {
        selectRowIndex(0, event.api);
      }
    }
  };

  const handleGridSizeChanged = (event: GridSizeChangedEvent) => {
    if (event.api && event.clientWidth) {
      event.api.sizeColumnsToFit();
    }
  };

  const handleRowDataChanged = (event: RowDataChangedEvent) => {
    if (event.api) {
      if (data === undefined) {
        event.api.showLoadingOverlay();
      } else {
        event.api.hideOverlay();
      }
      if (defaultFilterState) {
        event.api.setFilterModel(defaultFilterState);
      }

      event.api.deselectAll();

      handleSummaryFields(columns, event.api);
    }
  };

  const handleRowSelected = (event: RowSelectedEvent) => {
    if (onRowSelected && event.node.isSelected()) {
      onRowSelected(event.data);
    }
  };

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    if (onSelectionChanged && event.api) {
      onSelectionChanged(event.api.getSelectedRows());
    }
  };

  const handleRowGroupOpened = (event: RowGroupOpenedEvent) => {
    if (onRowGroupOpened && event.api) {
      onRowGroupOpened(event.data, event.rowIndex);
    }
  };

  const handleRowDragMove = (event: RowDragEvent) => {
    if (onRowDragMove) {
      onRowDragMove(event);
    }
  };

  const handleRowDragLeave = (event: RowDragEvent) => {
    if (onRowDragLeave) {
      onRowDragLeave(event);
    }
  };

  const handleRowDragEnd = (event: RowDragEvent) => {
    if (onRowDragEnd) {
      onRowDragEnd(event);
    }
  };

  const handleRowDragEnter = (event: RowDragEvent) => {
    if (onRowDragEnter) {
      onRowDragEnter(event);
    }
  };

  const handleCellClick = (e: CellClickedEvent) => {
    let canChange = !onCheckRowSelectability || onCheckRowSelectability(e.data);
    if (canChange) {
      if (!e.node.isSelected()) e.node.setSelected(true);
    } else if (e.event) {
      e.event.preventDefault();
    }
  };

  return (
    <Wrapper>
      {!suppressToolbar && (
        <Toolbar>
          {toolbarWidgets}
          {data && !suppressRecordCount && (
            <RecordCount
              showing={displayedRecordCount || data.length}
              total={data.length}
              selectedRowCount={selectedRowCount}
              recordType={recordType}
              onClearFilters={onClearFilters}
            />
          )}
        </Toolbar>
      )}
      <Flex className="overflow-auto">
        <AgGridReact
          onCellValueChanged={onCellValueChanged}
          accentedSort={true}
          colResizeDefault="shift"
          columnDefs={buildColumns(columns)}
          columnTypes={columnTypes}
          defaultColDef={defaultColDef(disableSorting, disableFiltering)}
          domLayout={autoHeight ? 'autoHeight' : undefined}
          getNodeChildDetails={getNodeChildDetails}
          getRowClass={getRowClass}
          getRowNodeId={getRowNodeId}
          pinnedBottomRowData={buildSummaryRow(columns)}
          rowData={data}
          rowMultiSelectWithClick={columns[0].checkboxSelection ? true : false}
          rowSelection={columns[0].checkboxSelection ? 'multiple' : 'single'}
          sortingOrder={['asc', 'desc']}
          suppressCellSelection={true}
          suppressDragLeaveHidesColumns={true}
          suppressHorizontalScroll={true}
          suppressRowClickSelection={true}
          onCellClicked={handleCellClick}
          onFilterChanged={handleFilterChangedEvent}
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridSizeChanged}
          onRowDataChanged={handleRowDataChanged}
          onRowSelected={handleRowSelected}
          onSelectionChanged={handleSelectionChanged}
          onRowGroupOpened={handleRowGroupOpened}
          onRowDragEnter={handleRowDragEnter}
          onRowDragMove={handleRowDragMove}
          onRowDragLeave={handleRowDragLeave}
          onRowDragEnd={handleRowDragEnd}
          enableBrowserTooltips={true}
        />
      </Flex>
    </Wrapper>
  );
};

const buildColId = ({ colId, headerName }: ColumnDefinition): string => (colId ? colId : _.camelCase(headerName));

export const buildColumns = (columns: ColumnDefinition[]) =>
  columns.map(column => {
    const { cellClass, cellRendererFramework, linkTo, pinnedRowCellRenderer, summaryEnabled, valueGetter, ...colDef } =
      column;

    const newColId = buildColId(column);

    const cellClassProperties = cellClass
      ? {
        cellClass: (params: CellClassParams) =>
          params.node.rowPinned ? '' : typeof cellClass === 'function' ? cellClass(params) : cellClass || ''
      }
      : {};

    const valueGetterProperties = valueGetter
      ? {
        valueGetter: (params: ValueGetterParams) =>
          params.node.rowPinned
            ? params.data[`${newColId}Summary`]
            : typeof valueGetter === 'string'
              ? valueGetter
              : valueGetter && valueGetter(params)
      }
      : {};

    return {
      ...colDef,
      ...cellClassProperties,
      ...valueGetterProperties,
      colId: newColId,
      cellRendererFramework: linkTo
        ? (params: ICellRendererParams) => {
          const to = linkTo(params);

          return to ? <RouteLink to={to}>{params.value}</RouteLink> : params.value;
        }
        : cellRendererFramework,
      pinnedRowCellRenderer: !summaryEnabled
        ? column === columns[0]
          ? () => 'TOTALS'
          : () => ''
        : pinnedRowCellRenderer
    };
  });

const buildSummaryRow = (columns: ColumnDefinition[]) => {
  if (checkSummaryFields(columns)) {
    return [];
  } else {
    return undefined;
  }
};

const checkSummaryFields = (columns: ColumnDefinition[]) => _.some(columns, column => column.summaryEnabled);

const defaultColDef = (disableSorting: boolean, disableFiltering: boolean): ColDef => ({
  filter: !disableFiltering,
  filterParams: {
    suppressAndOrCondition: true
  },
  resizable: true,
  sortable: !disableSorting
});

const handleSummaryFields = (columns: ColumnDefinition[], gridApi: GridApi) => {
  if (checkSummaryFields(columns)) {
    setSummaryRowTotals(columns, gridApi);
  }
};

const selectRowIndex = (rowIndex: number, api: GridApi) =>
  api.forEachNode(node => {
    if (node.rowIndex === rowIndex) {
      node.setSelected(true);
    }
  });

const setSummaryRowTotals = (columns: ColumnDefinition[], gridApi: GridApi) => {
  const totals = {};

  columns.forEach(column => {
    if (column.summaryEnabled) {
      const array: Array<unknown> = [];
      const newColId = buildColId(column);

      gridApi.forEachNodeAfterFilter(rowNode => array.push([gridApi.getValue(newColId, rowNode)]));
      // @ts-ignore
      totals[`${newColId}Summary`] = _.sumBy(array, '0');
    }
  });

  gridApi.setPinnedBottomRowData([totals]);
};

export default Grid;
