import { Formik } from 'formik';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import * as Yup from 'yup';
import { Button, Column } from '../../../shared/components';
import { FormRow, TextField } from '../../../shared/forms';
import FieldLabel from '../../../shared/forms/FieldLabel';
import { ResetPasswordMutation, ResetPasswordMutationVariables } from '../../graphql-types';
import ButtonSpinner from '../../shared/components/ButtonSpinner';
import Page from '../components/Page';

type Values = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const [mutate] = useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(mutation);

  if (submitted) {
    return <Confirmation />;
  }

  const handleSubmit = (values: Values) =>
    mutate({ variables: { email: values.email } }).then(() => setSubmitted(true));

  return (
    <Page
      content={
        <>
          <h2>FORGOT PASSWORD?</h2>
          <h4>Enter your email address below</h4>
          <hr style={{ width: '100%' }} />

          <Formik<Values> initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting, dirty, isValid, submitForm }) => (
              <Column width={8} offset={2}>
                <FormRow>
                  <FieldLabel col={3} text="Email Address" />
                  <TextField type="email" name="email" />
                </FormRow>

                <div className="my-4 d-flex justify-content-center">
                  <Button buttonStyle="dark" disabled={!dirty || !isValid} onClick={submitForm}>
                    {isSubmitting ? <ButtonSpinner message="SUBMITTING..." /> : 'SUBMIT'}
                  </Button>
                </div>
              </Column>
            )}
          </Formik>
        </>
      }
    />
  );
};

export default ForgotPassword;

const Confirmation: React.FC = () => (
  <Page
    content={
      <>
        <h1>Forgot Password Confirmation</h1>
        <p>
          If the email you entered matches an account in our system, then an email will be sent to it with instructions
          for changing your password.
        </p>
      </>
    }
  />
);

const validationSchema = Yup.object({
  email: Yup.string()
    .email()
    .label('Email Address')
    .required()
});

const mutation = gql`
  mutation ResetPasswordMutation($email: String!) {
    resetPassword(email: $email)
  }
`;
