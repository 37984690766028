import { FormikProps } from 'formik';

export const handleFieldChange = (
  form: FormikProps<any>,
  field: {
    onChange: React.ChangeEventHandler;
  },
  onChange?: (form: FormikProps<any>) => void
) => (event: React.ChangeEvent) => {
  field.onChange(event);

  if (onChange) {
    onChange(form);
  }
};

export const handleSelectChange = (
  form: FormikProps<any>,
  field: {
    onChange: React.ChangeEventHandler;
  },
  onChange?: (
    form: FormikProps<any>,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void
) => (event: React.ChangeEvent<HTMLSelectElement>) => {
  field.onChange(event);

  if (onChange) {
    onChange(form, event);
  }
};

export const handleInputChange = (
  form: FormikProps<any>,
  field: {
    onChange: React.ChangeEventHandler;
  },
  onChange?: (
    form: FormikProps<any>,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  field.onChange(event);

  if (onChange) {
    onChange(form, event);
  }
};
