import classNames from 'classnames';
import { Omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import dirtyStyles from '../dirty-styles';

type Size = 'small' | 'large';

type Props = {
  size?: Size;
};

const Select: React.FC<Props & Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size'>> = props => {
  const { className, size, ...attributes } = props;

  const classes = classNames('form-control', sizeToClass(size), className);

  return <StyledSelect className={classes} {...attributes} />;
};

const sizeToClass = (size: Size | undefined): string => {
  switch (size) {
    case 'large':
      return 'form-control-lg';
    case 'small':
      return 'form-control-sm';
    default:
      return '';
  }
};

export default Select;

// Options

const Option = styled.option`
  background-color: white;
  :checked {
    color: white;
    background-color: #007bff;
  }
`;

type Options = {
  blankText?: string;
  suppressBlank?: boolean;
};

export const optionsForSelect = <T extends {}>(
  items: T[],
  valueResolver: (item: T) => string | number,
  textResolver: (item: T) => string,
  options: Options = {}
): React.ReactNode => (
  <>
    {!options.suppressBlank && <Option value="">{options.blankText}</Option>}
    {items.map((item, index) => (
      <Option key={`${valueResolver(item)}-${index}`} value={valueResolver(item)}>
        {textResolver(item)}
      </Option>
    ))}
  </>
);

export const optionsForSelectFromLookupItems = <T extends { id: number | string; name: string }>(
  items: T[],
  options: Options = {}
): React.ReactNode => {
  return optionsForSelect(
    items,
    item => item.id,
    item => item.name,
    options
  );
};

const StyledSelect = styled.select`
  ${dirtyStyles};
`;
