import React from 'react';
import styled from 'styled-components';

const StyledHeading = styled.h2`
  background-color: #B38808;
  color: #333333;
  display: flex;
  font-size: 2rem;
  padding: 0.3rem 0 0.45rem;
`;

const StyledHeadingText = styled.span`
  flex: 1;
  text-align: center;
  text-transform: uppercase;
`;

type Props = {
  additionalContent?: React.ReactNode;
};

const Heading: React.FunctionComponent<Props> = ({
  additionalContent,
  children
}) => (
  <div>
    <StyledHeading>
      <StyledHeadingText>{children}</StyledHeadingText>
      {additionalContent}
    </StyledHeading>
  </div>
);

export default Heading;
