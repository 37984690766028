import { Field, FieldProps, FormikProps } from 'formik';
import _ from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { formatZipCode } from '../components/Grid/util';
import { Maybe } from '../maybe';
import { isDefined } from '../util';
import { toIsoDateString } from '../util/date';
import { formatDecimal, parseDecimal } from '../util/number';
import { fieldClassNames } from './class-helpers';
import DateInput from './DateInput';
import { handleInputChange } from './event-helpers';
import FieldErrors from './FieldErrors';
import FieldLabel from './FieldLabel';
import Input from './Input';
import { Size } from './Label';
import { configureValidation, formattedNumber } from './validation';

configureValidation();

const formGroupStyles = css`
  margin-bottom: 0.5rem !important;
`;

const FormGroup = styled.div.attrs({ className: 'form-group' })`
  ${formGroupStyles}
`;

const FormRow = styled.div.attrs({ className: 'form-group row' })`
  ${formGroupStyles}
`;

// TODO: Any way we could make the "name" props type-safe?

const TextField: React.FC<{
  disabled?: boolean;
  labelCol?: number;
  labelSize?: Size;
  labelText?: string;
  list?: string;
  name: string;
  onChange?: (form: FormikProps<any>, event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  type?: 'email' | 'password' | 'text';
}> = ({ disabled = false, labelCol, labelSize, labelText, name, onChange, type = 'text', required = false, list }) => (
  <>
    {labelText && <FieldLabel col={labelCol} required={required} size={labelSize} text={labelText} />}
    <div className="col">
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <>
            <Input
              type={type}
              {...field}
              className={fieldClassNames(form, name, disabled)}
              disabled={disabled}
              onChange={handleInputChange(form, field, onChange)}
              list={list}
            />
            <FieldErrors name={name} />
          </>
        )}
      </Field>
    </div>
  </>
);

const toTextFieldValue = (value: string | number | null): string => (value === null ? '' : value.toString());

const stringSchema = (
  fieldLabel: string,
  {
    required = false,
    maxLength
  }: {
    required?: boolean;
    maxLength?: number;
  } = {}
) => {
  let schema = Yup.string().label(fieldLabel);

  schema = maxLength
    ? schema.max(
        maxLength,
        // eslint-disable-next-line no-template-curly-in-string
        '${label} cannot exceed ${max} characters in length'
      )
    : schema;

  schema = required ? schema.required() : schema;

  return schema;
};

const DateField: React.FC<{
  disabled?: boolean;
  labelCol?: number;
  labelSize?: Size;
  labelText?: string;
  max?: Date;
  min?: Date;
  name: string;
  onChange?: (form: FormikProps<any>, event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}> = ({ disabled = false, labelCol, labelSize, labelText, max, min, name, onChange, required = false }) => (
  <>
    {labelText ? <FieldLabel col={labelCol} text={labelText} size={labelSize} required={required} /> : null}
    <div className="col">
      <DateInput disabled={disabled} max={max} min={min} name={name} onChange={onChange} />
      <FieldErrors name={name} />
    </div>
  </>
);

const toDateFieldValue = (value: Date | null): string => (value === null ? '' : toIsoDateString(value));

const handleDecimalBlur = (
  form: FormikProps<any>,
  field: {
    onBlur: (e: any) => void;
    name: string;
    value: string;
  },
  maxDigits?: number
) => (event: React.FocusEvent<HTMLInputElement>) => {
  field.onBlur(event);

  const newValue = _.flow(
    parseDecimal,
    i => Maybe.map((v) => formatDecimal(v, maxDigits), i),
    s => Maybe.withDefault('', s)
  )(field.value);

  form.setFieldValue(field.name, newValue);
};

const handleDecimalKeyDown: React.KeyboardEventHandler = event => {
  if (event.key.length === 1 && event.key.match(/[^0-9-,.]/)) {
    event.preventDefault();
  }
};

const DecimalField: React.FC<{
  disabled?: boolean;
  labelCol?: number;
  labelSize?: Size;
  labelText?: string;
  name: string;
  maxDigits?: number;
  required?: boolean;
  onChange?: (form: FormikProps<any>, event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ labelCol, labelSize, labelText, name, maxDigits = 2, disabled = false, required = false, onChange }) => {
  return (
    <>
      {labelText ? <FieldLabel col={labelCol} size={labelSize} text={labelText} required={required} /> : null}
      <div className="col">
        <Field name={name}>
          {({ field, form }: FieldProps) => (
            <>
              <Input
                type="text"
                {...field}
                className={fieldClassNames(form, name, disabled)}
                disabled={disabled}
                onBlur={handleDecimalBlur(form, field, maxDigits)}
                onKeyDown={handleDecimalKeyDown}
                onChange={handleInputChange(form, field, onChange)}
              />
              <FieldErrors name={name} />
            </>
          )}
        </Field>
      </div>
    </>
  );
};

const decimalSchema = (
  fieldLabel: string,
  { max, min, required = false }: { max?: number; min?: number; required?: boolean } = {}
) => {
  let schema = formattedNumber()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError('${label} must be a number')
    .label(fieldLabel);

  schema = required ? schema.required() : schema;
  schema = isDefined(min) ? schema.min(min) : schema;
  schema = isDefined(max) ? schema.max(max) : schema;

  return schema;
};

const toDecimalFieldValue = (value: number | null, maxDigits?: number): string => (value === null ? '' : formatDecimal(value, maxDigits));

const handleZipCodeBlur = (
  form: FormikProps<any>,
  field: {
    onBlur: (e: any) => void;
    name: string;
    value: string;
  }
) => (event: React.FocusEvent<HTMLInputElement>) => {
  field.onBlur(event);

  form.setFieldValue(field.name, formatZipCode(field.value));
};

const handleZipCodeKeyDown: React.KeyboardEventHandler = event => {
  if (event.key.length === 1 && event.key.match(/[^0-9-]/)) {
    event.preventDefault();
  }
};

const ZipCodeField: React.FC<{
  disabled?: boolean;
  labelText: string;
  labelSize?: 'small' | 'large';
  labelCol?: number;
  name: string;
  required?: boolean;
}> = ({ disabled = false, labelText, labelSize, labelCol, name, required = false }) => {
  return (
    <>
      <FieldLabel text={labelText} size={labelSize} col={labelCol} required={required} />
      <div className="col">
        <Field name={name}>
          {({ field, form }: FieldProps) => (
            <>
              <Input
                type="text"
                {...field}
                className={fieldClassNames(form, name)}
                disabled={disabled}
                onBlur={handleZipCodeBlur(form, field)}
                onKeyDown={handleZipCodeKeyDown}
              />
              <FieldErrors name={name} />
            </>
          )}
        </Field>
      </div>
    </>
  );
};

const zipCodeSchema = (fieldLabel: string, { required = false }: { required?: boolean } = {}) => {
  let schema = Yup.string()
    .label(fieldLabel)
    .matches(
      /^\d{5}(?:[-]?[0-9]{4})?$/,
      // eslint-disable-next-line no-template-curly-in-string
      'Valid ${label} formats: 12345, 123456789, or 12345-6789'
    );

  schema = required ? schema.required() : schema;

  return schema;
};

const toZipCodeFieldValue = (value: string | null): string => (value === null ? '' : formatZipCode(value));

export * from './basic';
export { default as Select, optionsForSelect, optionsForSelectFromLookupItems } from './basic/Select';
export { default as BooleanField, toBooleanFieldValue } from './BooleanField';
export { default as DerivedField } from './DerivedField';
export { default as FieldArrayErrors } from './FieldArrayErrors';
export { default as FieldSet } from './FieldSet';
export * from './formik';
export { default as FormikOnChange } from './FormikOnChange';
export { default as Input } from './Input';
export {
  default as IntegerField,
  integerSchema,
  toCurrencyFieldValue,
  toIntegerFieldValue,
  toPercentFieldValue
} from './IntegerField';
export { default as Label } from './Label';
export { default as NumberInput } from './NumberInput';
export { default as PhoneNumberField, phoneNumberSchema, toPhoneNumberFieldValue } from './PhoneNumberField';
export { default as PlainTextField } from './PlainTextField';
export { default as RadioButton } from './RadioButton';
export { default as SelectField, toSelectFieldValue } from './SelectField';
export { default as SQLCodeEditorField } from './SQLCodeEditorField';
export { default as TagsField } from './TagsField';
export { default as TextAreaField } from './TextAreaField';
export { urlSchema } from './url-schema';
export { default as WysiwygField } from './WysiwygField';
export { default as YearField, toYearFieldValue, yearSchema } from './YearField';
export {
  DateField,
  toDateFieldValue,
  DecimalField,
  toDecimalFieldValue,
  decimalSchema,
  FormGroup,
  FormRow,
  TextField,
  stringSchema,
  toTextFieldValue,
  ZipCodeField,
  toZipCodeFieldValue,
  zipCodeSchema
};
