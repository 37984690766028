import { Field, FieldProps, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { fieldClassNames } from './class-helpers';
import FieldErrors from './FieldErrors';
import FieldLabel from './FieldLabel';
import { handleInputChange } from './event-helpers';
import Input from './Input';

const handleYearKeyDown: React.KeyboardEventHandler = event => {
  if (event.key.length === 1 && event.key.match(/[^0-9]/)) {
    event.preventDefault();
  }
};

const YearField: React.FC<{
  labelText: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (
    form: FormikProps<any>,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}> = ({ labelText, name, required = false, disabled = false, onChange }) => (
  <>
    <FieldLabel text={labelText} required={required} />
    <div className="col">
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <>
            <Input
              type="number"
              {...field}
              className={fieldClassNames(form, name, disabled)}
              disabled={disabled}
              onKeyDown={handleYearKeyDown}
              min={1600}
              max={2199}
              onChange={handleInputChange(form, field, onChange)}
            />
            <FieldErrors name={name} />
          </>
        )}
      </Field>
    </div>
  </>
);

// eslint-disable-next-line no-template-curly-in-string
export const invalidYearMessage = '${label} must be a valid year (yyyy)';

export const toYearFieldValue = (value: number | null): number | '' =>
  value === null ? '' : value;

export const yearSchema = (
  fieldLabel: string,
  { required = false }: { required: boolean } = { required: false }
) => {
  const schema = Yup.number()
    .label(fieldLabel)
    .integer(invalidYearMessage)
    .min(1600, invalidYearMessage)
    .max(2199, invalidYearMessage);

  return required ? schema.required() : schema;
};

export default YearField;
