import { Formik } from 'formik';
import gql from 'graphql-tag';
import { throttle } from 'lodash';
import React, { useState } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Column, Link, LoadingMask, Modal } from '../../../shared/components';
import { FormikCheckbox, FormRow, PhoneNumberField, phoneNumberSchema, TextField } from '../../../shared/forms';
import FieldLabel from '../../../shared/forms/FieldLabel';
import { useQuery } from '../../../shared/graphql';
import {
  IsRegistrationEmailUniqueQuery,
  IsRegistrationEmailUniqueQueryVariables,
  RegisterMutation,
  RegisterMutationVariables,
  RegistrationEnvironmentQuery
} from '../../graphql-types';
import ButtonSpinner from '../../shared/components/ButtonSpinner';
import Page from '../components/Page';

type Values = {
  firstName: string;
  lastName: string;
  agency: string;
  jobTitle: string;
  phoneNumber: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  agreement: boolean;
  plWcAccess: boolean;
  hbAccess: boolean;
  programAccess?: boolean;
};

export default function Register() {
  const client = useApolloClient();
  const [isEmailValidating, setIsEmailValidating] = useState(false);
  const [programsSelected, setProgramsSelected] = useState(false);
  const [terms, setTerms] = useState(false);
  const [mutate] = useMutation<RegisterMutation, RegisterMutationVariables>(mutation);
  const [registrationInitiated, setRegistrationInitiated] = useState(false);
  const { data, loading } = useQuery<RegistrationEnvironmentQuery>(environmentQuery);

  if (loading || !data) {
    return <LoadingMask />;
  }

  if (registrationInitiated) {
    return <RegistrationInitiated />;
  }

  const handleSubmit = (values: Values) => {
    const input = {
      firstName: values.firstName,
      lastName: values.lastName,
      agency: values.agency,
      jobTitle: values.jobTitle,
      phoneNumber: values.phoneNumber,
      email: values.email,
      password: values.password,
      plWcAccess: values.plWcAccess,
      hbAccess: values.hbAccess
    };

    mutate({ variables: { input } }).then(() => setRegistrationInitiated(true));
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    agency: '',
    jobTitle: '',
    phoneNumber: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    agreement: false,
    plWcAccess: false,
    hbAccess: false
  };

  const isEmailValid = (email: string) => {
    setIsEmailValidating(true);

    return client
      .query<IsRegistrationEmailUniqueQuery, IsRegistrationEmailUniqueQueryVariables>({
        query,
        variables: { email }
      })
      .then(result => {
        setIsEmailValidating(false);
        return result.data.isEmailUnique;
      });
  };

  const throttledIsEmailValid = throttle(isEmailValid, 250);

  const validationSchema = Yup.object({
    firstName: Yup.string().label('First Name').required(),
    lastName: Yup.string().label('Last Name').required(),
    agency: Yup.string().label('District/Agency').required(),
    jobTitle: Yup.string().label('Job Title').required(),
    phoneNumber: phoneNumberSchema('Phone Number', { required: true }),
    email: Yup.string()
      .email()
      .label('Email')
      .test({
        name: 'unique',
        exclusive: true,
        message: 'This email address is already in use. Enter a unique email address.',
        test: email => !!(email && Yup.string().email().isValidSync(email) ? !!throttledIsEmailValid(email) : true)
      })
      .required(),
    confirmEmail: Yup.string()
      .label('Confirm Email')
      .oneOf([Yup.ref('email'), null], 'Emails must match')
      .required(),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
        'Password must be 6 characters or more in length. It must contain letters (uppercase and lowercase) and numbers.'
      )
      .label('Password')
      .required(),
    confirmPassword: Yup.string()
      .label('Confirm Password')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(),
    agreement: Yup.boolean().oneOf(
      [true, null],
      'Please click on the checkbox to agree to the terms before proceeding.'
    )
  });

  const handleValidate = (values: Values) => {
    setProgramsSelected(values.hbAccess || values.plWcAccess);
  };

  return (
    <Page
      content={
        <Formik<Values>
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {({ dirty, values, isValid, submitForm, isSubmitting }) => (
            <>
              <FormContainer>
                <h2>REGISTER</h2>
                <h4>Create a new account (New Registration)</h4>
                <hr style={{ width: '100%' }} />
                <Column width={10} offset={1} className="d-flex flex-column">
                  <FormRow>
                    <FieldLabel col={labelCol} text="First Name" />
                    <TextField name="firstName" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="Last Name" />
                    <TextField name="lastName" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="District/Agency" />
                    <TextField name="agency" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="Job Title" />
                    <TextField name="jobTitle" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="Phone Number" />
                    <PhoneNumberField name="phoneNumber" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="Email" />
                    <TextField type="email" name="email" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="Confirm Email" />
                    <TextField type="email" name="confirmEmail" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="Password" />
                    <TextField type="password" name="password" />
                  </FormRow>
                  <FormRow>
                    <FieldLabel col={labelCol} text="Confirm Password" />
                    <TextField type="password" name="confirmPassword" />
                  </FormRow>
                  <div className="row">
                    <FieldLabel size="small" text="Access to features for Property/Liability and/or Workers' Compensation" />
                    <FormikCheckbox name="plWcAccess" />
                  </div>
                  <div className="row">
                    <FieldLabel size="small" text="Access to features for Health Benefits" /><br />
                    <FieldLabel
                      size="small"
                      text="(only for Agency Health Benefits contacts, this is not an individual employee portal.)"
                    />
                    <FormikCheckbox name="hbAccess" />
                  </div>
                  <div className="row" style={{ marginTop: '-10px' }}>
                  </div>
                  {values.agreement && !programsSelected ? (
                    <FormRow>
                      <div className="col-4" />
                      <div className="col">
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          You must select at least one Program to Access with your MemberPlus account.
                        </div>
                      </div>
                    </FormRow>
                  ) : undefined}
                  <FormRow>
                    <br />
                    <span style={{ margin: '8px 8px 0 0', padding: 0 }}>
                      By checking this box, I agree to the terms and conditions{' '}
                      <Link onClick={() => setTerms(true)} style={{ color: '#b28705' }}>
                        listed here.
                      </Link>
                    </span>
                    <FormikCheckbox name="agreement" className="mr-2 p-0" />

                    {terms && <Terms onClose={() => setTerms(false)} />}

                    <Button
                      disabled={!dirty || !isValid || isEmailValidating || !programsSelected}
                      onClick={submitForm}
                    >
                      {isSubmitting ? <ButtonSpinner message="Registering User..." /> : 'REGISTER'}
                    </Button>
                  </FormRow>
                </Column>
              </FormContainer>
            </>
          )}
        </Formik>
      }
    />
  );
}

const labelCol = 4;

const Terms: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <Modal
    onClose={onClose}
    title="Terms"
    body={
      <>
        <p>
          <b>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE</b>
        </p>

        <p>
          By using this site, you signify your assent to these conditions of use.
          <br />
        </p>

        <p>
          <b>LIMITATION OF LIABILITY </b>
          <br />
          Under no circumstances, including, but not limited to, negligence, shall SDRMA be liable for any direct,
          indirect, special or consequential damages that result from the use of, or the inability to use, the materials
          in this site, even if SDRMA or an SDRMA authorized representative has been advised of the possibility of such
          damages. In no event shall SDRMA have any liability to you for damages, losses and causes of action (whether
          in contract, tort (including, but not limited to, negligence), or otherwise) for accessing this site.
        </p>
        <p>
          <b>Jurisdiction &amp; Enforeability</b>
          <br />
          All claims, disputes or disagreements which may arise out of the interpretation, performance or in any way
          relating to your use of this site and any and all other SDRMA Site(s), shall be submitted exclusively to the
          jurisdiction of the State or federal courts located in the State of Illinois.
        </p>
        <p>
          SDRMA reserves the right to modify the Terms of Use at any time. Continued use of this site by you will
          constitute your acceptance of any revisions to the Terms of Use. Please check this page regularly.
        </p>
        <blockquote style={{ fontSize: '13px' }}>
          Copyright / Trademark
          <br />
          Third-Party Sites
          <br />
          Privacy &amp; Security
          <br />
          Password Registration
          <br />
          Disclaimer of Warranty
          <br />
          Limitation of Liability
          <br />
          Jurisdiction / Enforceability
          <br />
          Underwriting Companies
          <br />
          <br />
        </blockquote>
        <p>
          <b>Copyright / Trademark </b>
          <br />
          Except where otherwise indicated, all materials contained in this Web site are the copyrighted property of
          SDRMA, its affiliated companies and/or third party licensors.
        </p>
        <p>
          Permission is hereby granted to use, copy and distribute these materials as presented in this Web site and
          without alteration for non-commercial purposes only; provided that all copyright and other proprietary notices
          appear in all copies in the same manner as the original. All other uses are prohibited.
        </p>
        <p>
          Except as expressly provided herein, you shall not use any portion of this Web site, or any other intellectual
          property of SDRMA, on any other Web site, in the source code of any other Web site, or in any other printed or
          electronic materials. Except as expressly provided herein, you shall not modify, publish, reproduce,
          republish, create derivative works, copy, upload, post, transmit, distribute, or otherwise use any of this Web
          site's content or frame this Web site within any other Web site without our prior written permission.
          Systematic retrieval of data or other content from this site to create or compile, directly or indirectly, a
          collection, compilation, database or directory, without prior written permission from SDRMA, is prohibited.
          Linking from another Web site to any page in this Web site is strictly prohibited without prior written
          permission.
        </p>
        <p>
          <b>Third-Party Sites </b>
          <br />
          This site contains links to other Internet Web sites ("Third-party Sites") that are not maintained by SDRMA.
          These links are provided solely for your convenience. SDRMA makes no warranties or representations about the
          content of, any products or services offered by, or the intellectual property compliance of, such Third-party
          Sites. We recommend that you take the time to read the privacy policies and user agreements of these sites.
        </p>
        <p>
          <b>Disclaimer of Warranty </b>
          <br />
          The content and materials in this site are provided "as is" and without representations or warranties of any
          kind, either express or implied. SDRMA expressly disclaims all warranties, express or implied, with respect to
          this site including, but not limited to, implied warranties of merchantability, fitness for a particular
          purpose and non-infringement. SDRMA does not warrant or represent that the functions or operation of this site
          will be uninterrupted or error-free, that defects will be corrected, or that this site, its servers or any
          e-mail sent from SDRMA are free of viruses or other harmful components. Some states do not allow the
          disclaimer of implied warranties, so the foregoing disclaimer may not apply to you.
        </p>
      </>
    }
    footer={
      <Button className="ml-auto" buttonStyle="dark" onClick={onClose}>
        Close
      </Button>
    }
  />
);

const RegistrationInitiated: React.FC = () => (
  <Page
    content={
      <>
        <h2>REGISTRATION INITIATED</h2>
        <p>
          Your account registration is not complete yet. <br /> <br />
          You will receive an email which will include instructions for completing your online registration. Once the
          registration process is complete and your account is approved and activated by SDRMA, you will receive an
          email notification.
          <br />
          <br />
          Member Services
          <br />
          800.537.7790
        </p>
      </>
    }
  />
);

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  > :not(#fields) {
    padding-left: 15px;
  }
`;

const query = gql`
  query IsRegistrationEmailUniqueQuery($email: String!) {
    isEmailUnique(email: $email)
  }
`;

const mutation = gql`
  mutation RegisterMutation($input: RegisterUserAccountInput!) {
    register(input: $input)
  }
`;

const environmentQuery = gql`
  query RegistrationEnvironmentQuery {
    environment
  }
`;
