/* eslint-disable */
import ApolloClient, { ApolloQueryResult, OperationVariables, QueryOptions } from 'apollo-boost';
import { ErrorHandler } from 'apollo-link-error';
import convertJsonDateStringsToDates from '../../shared/util/convert-json-date-strings-to-dates';
import { getApiRoot } from '../environment';

const queryOptions: Partial<QueryOptions> = { fetchPolicy: 'network-only' };

export default function(developmentSubpath: string, onError?: ErrorHandler) {
  const apiRoot = getApiRoot(developmentSubpath, process.env.NODE_ENV);

  const client = new ApolloClient({
    onError,
    uri: `${apiRoot}/graphql`
  });

  client.defaultOptions = {
    query: queryOptions,
    watchQuery: queryOptions
  };

  const query = client.query;

  // Override query to parse JSON Date strings
  // TODO: Is there a better way?  This feels hacky!
  client.query = <T, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>
  ): Promise<ApolloQueryResult<T>> => query(options).then(convertJsonDateStringsToDates);

  return client;
}
