import React from 'react';

type Props = {
  disabled?: boolean;
  onClick: () => void;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Link: React.FC<Props> = ({ disabled = false, href = '#', onClick, children, ...props }) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    onClick();
  };

  return disabled ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      {...props}
      style={{
        color: '#999595'
      }}
    >
      {children}
    </a>
  ) : (
    <a href={href} onClick={handleClick} {...props}>
      {children}
    </a>
  );
};

export default Link;
