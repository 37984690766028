import React from 'react';
import styled, { css } from 'styled-components';

const commonStyles = css`
  border: 1px solid #cfcfcf;
  padding: 0 10px;
  border-radius: 4px;
  margin-bottom: 8px;
`;

type StyleProps = { fillHeight: boolean };

const FieldSetWithLegend = styled.fieldset<StyleProps>`
  ${commonStyles};

  ${props =>
    props.fillHeight &&
    css`
      height: 100%;
    `};
`;

const FieldSetWithoutLegend = styled.fieldset<StyleProps>`
  ${commonStyles};

  ${props =>
    props.fillHeight &&
    css`
      height: calc(100% - 0.6rem);
    `};

  margin-top: 0.6rem;
  padding-top: 1rem;
`;

const Legend = styled.legend`
  color: #555555;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0 3px;
  width: auto;
`;

const FieldSet: React.FC<{ fillHeight?: boolean; legend?: string; style?: React.CSSProperties }> = ({
  fillHeight = false,
  legend,
  style,
  children
}) =>
  legend ? (
    <FieldSetWithLegend fillHeight={fillHeight} style={style}>
      <Legend>{legend}</Legend>
      {children}
    </FieldSetWithLegend>
  ) : (
    <FieldSetWithoutLegend fillHeight={fillHeight} style={style}>
      {children}
    </FieldSetWithoutLegend>
  );

export default FieldSet;
