import React from 'react';
import FieldErrors from './FieldErrors';
import FieldLabel from './FieldLabel';
import { FormikTextArea } from './formik';
import { Size } from '../components';

const TextAreaField: React.FC<{
  disabled?: boolean;
  labelText?: string;
  labelCol?: number;
  labelSize?: Size;
  name: string;
  required?: boolean;
  rows?: number;
}> = ({ disabled = false, labelText, labelCol, labelSize, name, required = false, rows }) => (
  <>
    {labelText && <FieldLabel
        col={labelCol}
        required={required}
        size={labelSize}
        text={labelText}
      />}
    <div className="col">
      <FormikTextArea name={name} disabled={disabled} rows={rows} />
      <FieldErrors name={name} />
    </div>
  </>
);

export default TextAreaField;
