const formatPhoneNumber = (rawPhoneNumber: string): string => {
  const value = rawPhoneNumber.replace(/[^0-9]/g, '');
  const phoneNumberArray = value.match(/^(\d{3})(\d{3})(\d{4})$/);

  return !phoneNumberArray
    ? rawPhoneNumber
    : '(' +
        phoneNumberArray[1] +
        ') ' +
        phoneNumberArray[2] +
        '-' +
        phoneNumberArray[3];
};

const formatZipCode = (zipCode: string): string => {
  if (zipCode) {
    return zipCode.match(/^[0-9\s]{9}$/)
      ? zipCode.slice(-4) === '    '
        ? zipCode.slice(0, 5)
        : zipCode.slice(0, 5) + '-' + zipCode.slice(-4)
      : zipCode;
  } else {
    return '';
  }
};

export { formatPhoneNumber, formatZipCode };
