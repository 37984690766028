import React from 'react';
import { inputClassNames } from '../class-helpers';
import styled from 'styled-components';
import dirtyStyles from '../dirty-styles';

const TextArea: React.FC<{
  disabled?: boolean;
  id?: string;
  isDirty?: boolean;
  invalid?: boolean;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  rows?: number;
  className?: string;
}> = ({ disabled, id, isDirty, invalid, value, onChange, onBlur, rows }) => {
  return (
    <StyledTextArea
      id={id}
      className={inputClassNames({ dirty: isDirty, invalid })}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      rows={rows}
    />
  );
};

const StyledTextArea = styled.textarea`
  ${dirtyStyles}
`;

export default TextArea;
