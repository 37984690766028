import { Field, FieldProps, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { formatPhoneNumber } from '../components/Grid/util';
import { fieldClassNames } from './class-helpers';
import FieldErrors from './FieldErrors';
import FieldLabel from './FieldLabel';
import Input from './Input';
import { Size } from './Label';

const handlePhoneNumberBlur = (
  form: FormikProps<any>,
  field: {
    onBlur: (e: any) => void;
    name: string;
    value: string;
  }
) => (event: React.FocusEvent<HTMLInputElement>) => {
  field.onBlur(event);
  form.setFieldValue(field.name, formatPhoneNumber(field.value));
};

const handlePhoneNumberKeyDown: React.KeyboardEventHandler = event => {
  if (event.key.length === 1 && event.key.match(/[^0-9-.()\s]/)) {
    event.preventDefault();
  }
};

const PhoneNumberField: React.FC<{
  disabled?: boolean;
  labelCol?: number;
  labelSize?: Size;
  labelText?: string;
  name: string;
  required?: boolean;
}> = ({ disabled = false, labelCol, labelSize, labelText, name, required = false }) => {
  return (
    <>
      {labelText && <FieldLabel col={labelCol} size={labelSize} required={required} text={labelText} />}
      <div className="col">
        <Field name={name}>
          {({ field, form }: FieldProps) => (
            <>
              <Input
                type="text"
                {...field}
                className={fieldClassNames(form, name)}
                disabled={disabled}
                onBlur={handlePhoneNumberBlur(form, field)}
                onKeyDown={handlePhoneNumberKeyDown}
              />
              <FieldErrors name={name} />
            </>
          )}
        </Field>
      </div>
    </>
  );
};

export default PhoneNumberField;

export const phoneNumberSchema = (fieldLabel: string, { required = false }: { required?: boolean } = {}) => {
  let schema = Yup.string()
    .label(fieldLabel)
    .matches(
      /^(\D*\d){10}\D*$/,
      // eslint-disable-next-line no-template-curly-in-string
      'Valid ${label} formats: 9995551212, (999) 555-1212, 999-555-1212, or 999.555.1212.'
    );

  schema = required ? schema.required() : schema;

  return schema;
};

export const toPhoneNumberFieldValue = (value: string | null): string =>
  value === null ? '' : formatPhoneNumber(value);
