import React from 'react';
import Label, { Size } from './Label';
import RequiredFieldIndicator from './RequiredFieldIndicator';

const FieldLabel: React.FC<{
  col?: number;
  required?: boolean;
  size?: Size;
  text: string;
}> = ({ col = 2, required = false, size, text }) => (
  <Label col={col} size={size}>
    {text}: {required ? <RequiredFieldIndicator /> : null}
  </Label>
);

export default FieldLabel;
