import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line no-unexpected-multiline
const Overlay = styled.div.attrs({ className: 'd-flex align-items-center' })<{
  fullScreen: boolean;
}>`
  position: ${props => (props.fullScreen ? 'fixed' : 'absolute')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
`;

const Content = styled.div.attrs({ className: 'rounded shadow p-4' })`
  background-color: rgb(229, 229, 229);
  margin: 0 auto;
`;

const LoadingMask: React.FC<{ fullScreen?: boolean; message?: string }> = ({
  fullScreen = false,
  message = 'Loading...'
}) => (
  <Overlay fullScreen={fullScreen}>
    <Content>
      <div className="text-center">
        <div className="fas fa-spinner fa-pulse" />
        <div>{message}</div>
      </div>
    </Content>
  </Overlay>
);

export default LoadingMask;
